import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import isEqual from 'lodash/isEqual';
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TableChartIcon from "@material-ui/icons/TableChart";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { Collapse } from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import * as URLS from "../../../utils/URLS";
import { useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import UserPermissions from "../../users/UserPermissions";
import Cookies from "js-cookie";
import { useHistory } from 'react-router-dom';
import { checkAccess } from "../../../redux/actions/userAction";
import { getPinnedPages } from "../../../redux/actions/UIActions";


const styles = (theme) => ({
	menuHeader: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	menuHeaderPrimary: {
		color: theme.palette.common.white,
	},
	item: {
		paddingTop: 10,
		paddingBottom: 10,
		color: "rgba(255, 255, 255, 0.7)",
		"&:hover,&:focus": {
			backgroundColor: "rgba(255, 255, 255, 0.08)",
		},
	},
	child: {
		marginLeft: "35px",
	},
	itemMenu: {
		backgroundColor: "#232f3e",
		boxShadow: "0 -1px 0 #404854 inset",
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		cursor: "pointer",
	},
	firebase: {
		fontSize: 24,
		color: theme.palette.common.white,
	},
	itemActiveItem: {
		color: "#4fc3f7 !important",
	},
	itemPrimary: {
		fontSize: "inherit",
	},
	itemIcon: {
		minWidth: "auto",
		marginRight: theme.spacing(2),
	},
	divider: {
		marginTop: theme.spacing(2),
	},
	child: {
		marginLeft: "20px",
	},
});
function Navigator(props) {
	const location = useLocation()	
	const { classes, ...other } = props;
	const [current_menu, setCurrentMenu] = useState("");
	const [menus, setMenus] = useState([
		{
			id: "Dashboard",
			icon: <DashboardIcon />,
			expanded: false,
			children: [
				{ id: "Dashboard", link: URLS.ADMIN },
				{
					id: "Positions - Wintermute",
					link: URLS.DASHBOARD.POSITIONS_WINTERMUTE,
				},
				{ id: "Positions - Venue", link: "#" },
				{ id: "Positions - Platform", link: "#" },
				{ id: "Positions - Cash", link: "#" },
				{
					id: "Wallet Balances - Cold",
					link: URLS.DASHBOARD.WALLET_BALANCES_COLD,
				},
			],
		},
		{
			id: "Compliance",
			icon: <SettingsApplicationsIcon />,
			expanded: false,
			children: [
				{
					id: "B2B",
					active: false,
					link: URLS.COMPLIANCE.B2B,
				},
				{
					id: "Bank Verification",
					active: false,
					link: URLS.COMPLIANCE.BANK_VERIFICATION,
				},
				{
					id: "Client Valuation Statement",
					active: false,
					link: URLS.COMPLIANCE.CLIENT_VALUATION_STATEMENT,
				},
				{
					id: "Compliance Metrics",
					active: false,
					link: URLS.AUTO_RECON.COMPLIANCE_METRICS,
				},
				{
					id: "Compliance Report",
					active: false,
					link: URLS.COMPLIANCE.COMPLIANCE_REPORT,
				},
				{
					id: "Duplicate Wallet Addresses",
					active: false,
					link: URLS.COMPLIANCE.DUPLICATE_WALLET_ADDRESSES,
				},
				{
					id: "EDD",
					active: false,
					link: URLS.COMPLIANCE.EDD,
				},
				{ id: "KYC", active: false, link: URLS.COMPLIANCE.KYC },
				{
					id: "KYT Rejected",
					active: false,
					link: URLS.COMPLIANCE.KYT_REJECTED,
				},
				{
					id: "Manage Businesses",
					active: false,
					link: URLS.COMPLIANCE.MANAGE_COMPANIES,
				},
				{
					id: "Over60 Countdown",
					active: false,
					link: URLS.COMPLIANCE.OVER60_COUNTDOWN,
				},
				{
					id: "Transaction Monitoring",
					active: false,
					link: URLS.COMPLIANCE.TRAVEL_RULE,
				},
				{
					id: "Login - User Session - Restricted",
					active: false,
					link: URLS.COMPLIANCE.RESTRICTED_USER_SESSIONS
				},
			],
		},
		{
			id: "Reports",
			expanded: false,
			icon: <TableChartIcon />,
			children: [
				{ id: "Reports", active: false, link: URLS.REPORTS },
				{ id: "Withdraw Cash", active: false, link: URLS.CASH_WITHDRAWS },
				{ id: "Withdraw Crypto", active: false, link: URLS.CRYPTO_WITHDRAWALS },
				{ id: "Deposit Cash", active: false, link: URLS.CASH_DEPOSITS },
				{ id: "Deposit Crypto", active: false, link: URLS.CRYPTO_DEPOSITS },
				{
					id: "Transaction - Coinpass",
					active: false,
					link: URLS.TRANCTIONS,
				},
				{ id: "Transaction - Orders", active: false, link: URLS.LIMIT_ORDERS },
			],
		},
		{
			id: "Operations",
			expanded: false,
			icon: <SettingsApplicationsIcon />,
			children: [
				{
					id: "User Fiat History",
					active: false,
					link: URLS.USER_FIAT_HISTORY,
				},
				{
					id: "User Fiat Refunds",
					active: false,
					link: URLS.OPERATION.USER_FIAT_REFUNDS,
				},
				{
					id: "Failed Stripe Deposits",
					active: false,
					link: URLS.OPERATION.FAILED_STRIPE_DEPOSITS,
				},
				{
					id: "Pending - Withdraw Crypto",
					active: false,
					link: URLS.CRYPTO_WITHDRAW_PENDINGS,
				},
				{
					id: "Refund - Withdraw Crypto",
					active: false,
					link: URLS.CRYPTO_WITHDRAW_REFUND,
				},
				{
					id: "Refund - Withdraw Cash",
					active: false,
					link: URLS.CASH_WITHDRAW_REFUND,
				},
				{
					id: "Pending - Withdraw Cash",
					active: false,
					link: URLS.WITHDRAW_PENDINGS,
				},
			],
		},
		{
			id: "User Configurations",
			expanded: false,
			icon: <SettingsApplicationsIcon />,
			children: [
				{
					id: "Users",
					active: false,
					link: URLS.USERS,
				},
				{
					id: "Login - User Session",
					active: false,
					link: `${URLS.USER_SESSIONS}/SignIn`,
				},
				{
					id: "Register - User Session",
					active: false,
					link: `${URLS.USER_SESSIONS}/SignUp`,
				},
				{
					id: "Reset - User Session",
					link: `${URLS.USER_SESSIONS}/ForgotPassword`,
				},
				{
					id: "Auto Trade",
					active: false,
					link: URLS.AUTO_TRADE,
				},
			],
		},
		{
			id: "System Configuration",
			expanded: false,
			icon: <SettingsApplicationsIcon />,
			children: [
				{
					id: "Currencies",
					link: URLS.CURRENCY,
				},
				{
					id: "Coins",
					link: URLS.COIN,
				},
				{
					id: "Group",
					link: URLS.COIN_GROUP,
				},
				{
					id: "Account Tiers",
					active: false,
					link: URLS.SYSTEM_CONFIG.ACCOUNT_TIERS,
				},
				{
					id: "Web Control",
					child_expanded: false,
					children: [
						{
							id: "Manage Deleted Users",
							active: false,
							link: URLS.SYSTEM_CONFIG.MANAGE_DELETED_USERS,
						},
						{
							id: "Manage Archived Users",
							active: false,
							link: URLS.SYSTEM_CONFIG.MANAGE_ARCHIVED_USERS,
						},
						{
							id: "General Settings",
							active: false,
							link: URLS.SYSTEM_CONFIG.GENERAL_SETTINGS,
						},
						{
							id: "Wallet Lookup",
							active: false,
							link: URLS.COMPLIANCE.WALLET_LOOKUP,
						},
					],
				},
				{
					id: "Manage User Withdraw Wallet Addresses",
					active: false,
					link: URLS.SYSTEM_CONFIG.MANAGE_WALLET_ADDRESS,
				},
				{
					id: "Manage User Deposit Wallet Addresses",
					active: false,
					link: URLS.SYSTEM_CONFIG.MANAGE_DEPOSIT_WALLET_ADDRESS,
				},
				{
					id: "LP Directing",
					link: URLS.SYSTEM_CONFIG.LP_DIRECTING,
				},
				{
					id: "Audit Logs",
					link: URLS.SYSTEM_CONFIG.AUDIT_LOGS,
				},
			],
		},
		{
			id: "Affiliates",
			expanded: false,
			icon: <CreditCardIcon />,
			children: [
				{
					id: "Referrers List",
					active: false,
					link: URLS.AFFILIATES.REFERRERS,
				},
				{
					id: "Referral Codes",
					active: false,
					link: URLS.AFFILIATES.REFERRAL_CODES,
				},
				{
					id: "Referral Links",
					active: false,
					link: URLS.AFFILIATES.REFERRAL_LINKS,
				},
			],
		},
		{
			id: "Legacy",
			expanded: false,
			icon: <AccountBalanceIcon />,
			children: [
				{ id: "Buy Request", active: false, link: `${URLS.LEGACY}/1` },
				{ id: "Sell Request", active: false, link: `${URLS.LEGACY}/2` },
				{
					id: "Pending - Deposit Cash",
					active: false,
					link: URLS.CASH_DEPOSITS_PENDINGS,
				},
				{
					id: "Declined - Withdraw Crypto",
					active: false,
					link: URLS.CRYPTO_WITHDRAW_DECLINED,
				},
			],
		},
		{
			id: "Auto Recon",
			expanded: false,
			icon: <AccountBalanceIcon />,
			children: [
				{
					id: "Trade Analysis",
					active: false,
					link: URLS.AUTO_RECON.TRADE_ANALYSIS,
				},
				{
					id: "Platform Metrics",
					active: false,
					link: URLS.AUTO_RECON.PLATFORM_METRICS,
				},
				{
					id: "Application Metrics",
					active: false,
					link: URLS.AUTO_RECON.APPLICATION_METRICS,
				},
				{
					id: "Performance Metrics",
					active: false,
					link: URLS.AUTO_RECON.PERFORMANCE_METRICS,
				},
				{
					id: "B2B vs B2C",
					active: false,
					link: URLS.AUTO_RECON.B2B_VS_B2C,
				},
				{
					id: "Oanda Reporting (B2B)",
					active: false,
					link: URLS.AUTO_RECON.OANDA_REPORTING,
				},
			]
		},
		{
			id: "Liquidity",
			expanded: false,
			icon: <AccountBalanceIcon />,
			children: [
				{
					id: "Auto Sweeping",
					active: false,
					link: URLS.LIQUIDITY.AUTO_SWEEPING,
				},
			],

		},
	]);
	
	const { user_level } = useSelector( state => state.user ) 
	const user_level_cookie = Cookies.get('user_level') 
	const permission = user_level == null ? user_level_cookie : user_level
	const history = useHistory();
	const { pinnedPages } = useSelector((state) => state.ui);
	const dispatch = useDispatch();

	useEffect(() => {
    dispatch(getPinnedPages());
  }, [dispatch]);
	
	useEffect(() => {
    const addPinnedPages = () => {
      setMenus((prev) => {
        let newMenus = [...prev];
        const pinned = [];

        (pinnedPages|| [])?.forEach((pinnedPageId) => {
          const foundPage = newMenus
            ?.flatMap((menu) => menu.children)
            ?.flatMap((child) => child.children || child)
            ?.find((child) => child?.link === pinnedPageId);
          if (foundPage) pinned.push(foundPage);
        });

        return [
          {
            id: "Pinned Pages",
            icon: <DashboardIcon />,
						...prev.filter((p) => p.id === "Pinned Pages")[0],
            children: pinned,
          },
          ...prev.filter((p) => p.id !== "Pinned Pages"),
        ];
      });
    };

    addPinnedPages();
  }, [pinnedPages]); 


	const checkPermission = () => {
		const pages = new Map(
			menus
				.map(m => m.children
					.map(x => ({ ...x, parent_id: m.id}))
				)
				.flat()
				.map(c => 
					c.children ? 
						c.children.map(x => 
							({ ...x, id: c.id, parent_id: c.parent_id })
						) 
						: c
				)
				.flat()
				.map(c => [c.link, [c.id, c.parent_id]]),
		);

		const pathname = location?.pathname
			?.trim()
			?.replace(/\/*$/gmi, '');

		// let val1 = null // id of current page parent
		// let val2 = null // id of current page
		const [val2 = null, val1 = null] = pages.get(pathname) ?? [];

		console.log(
			{
				val1,
				val2,
				pathname,
				permission,
			},
		);

		try {
			if(permission && 
				(
					UserPermissions[permission].includes('all') || 
					UserPermissions[permission].includes(val2) || 
					UserPermissions[permission+'_child'].includes(val1+'All') || 
					UserPermissions[permission+'_child'].includes(val2) || 
					UserPermissions[permission+'_child_child'].includes('all')
				)
			){
				return true
			} else {
				props.checkAccess(false)
				return false	
			}
		} catch (error) {
			console.log('There is an error while checkin permission: '+error);
		}
	}

	useEffect(()=>{
		if (location.pathname !== '/admin-edit-profile') {
			setTimeout(() => {
				if (!checkPermission()) {
					history.push({
						pathname: "/",
					});					
				}
			}, 500);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[history, location.pathname])

	useEffect(() => {
		setMenus((prev) => {
			const newMenu = prev.map((menu) => {
				if (!current_menu) return menu;
	
				if (menu.id === current_menu.id) {
					return { ...menu, expanded: !menu.expanded };
				} else {
					return { ...menu, expanded: false };
				}
			});
			return newMenu;
		});
		return () => setCurrentMenu(null);
	}, [current_menu]);
		
	const updateMenuState = (menu, currentPath) => {
		const updatedChildren = menu.children.map((child) => {
			if (currentPath === child.link) {
				return { ...child, active: true };
			} else if (child.children) {
				const nestedChildren = child.children.map((nestedChild) =>
					currentPath === nestedChild.link
						? { ...nestedChild, active: true }
						: nestedChild
				);
	
				if (!isEqual(child.children, nestedChildren)) {
					return {
						...child,
						child_expanded: true,
						children: nestedChildren,
					};
				}
			}
	
			return child;
		});
	
		if (!isEqual(menu.children, updatedChildren)) {
			return { ...menu, expanded: true, children: updatedChildren };
		}
	
		return menu;
	};
	
	useEffect(() => {
		setMenus((prevMenus) =>
			prevMenus.map((menu) => updateMenuState(menu, location.pathname))
		);
	}, [location.pathname]);
	
	const handleClick = (menu) => {
		setCurrentMenu(menu);
	};

	const handleChildClick = (menu_id, child_menu_id) => {
		
		const new_menu = menus.map((menu) => {
			if (menu.id === menu_id) {
				menu.children.map((child) => {
					if (child.id === child_menu_id) {
						child.child_expanded = child.child_expanded ? false : true;
					}
					return child;
				});
			}

			return menu;
		});
		setMenus(new_menu);
	};

	return (
		<Drawer className="hide-on-print" variant="permanent" {...other}>
			<List disablePadding>
				<ListItem
					className={clsx(classes.firebase, classes.item, classes.itemMenu)}
				>
					Coinpass - Admin
				</ListItem>
				{menus.map((menu) => 
				{
					if (permission && (UserPermissions[permission].includes('all') || UserPermissions[permission].includes(menu.id))) {
						return (
							<React.Fragment key={menu.id}>
								<ListItem
									className={clsx(classes.item, classes.itemMenu)}
									onClick={handleClick.bind(this, menu)}
								>
									<ListItemIcon className={classes.itemIcon}>
										{menu.icon}
									</ListItemIcon>
									<ListItemText
										classes={{
											primary: classes.itemPrimary,
										}}
									>
										{menu.id}
									</ListItemText>
									{menu.expanded ? <ExpandLess /> : <ExpandMore />}
								</ListItem>
								{menu.children.map(
									({
										id: childId,
										icon,
										active,
										link,
										children,
										child_expanded,
									}) => {
										if (UserPermissions[permission+'_child'].includes('all') || UserPermissions[permission+'_child'].includes(menu.id+'All') || UserPermissions[permission+'_child'].includes(childId)) {
											return (
												<Collapse
													key={childId}
													in={menu.expanded}
													timeout="auto"
													unmountOnExit
												>
													{children ? (
														<>
															<ListItem
																key={childId}
																button
																className={clsx(
																	classes.divider,
																	classes.item,
																	active && classes.itemActiveItem
																)}
																onClick={handleChildClick.bind(
																	this,
																	menu.id,
																	childId
																)}
															>
																<ListItemText
																	className={classes.child}
																	classes={{
																		primary: clsx(classes.itemPrimary, active && classes.itemActiveItem),
																	}}
																>
																	{childId}
																</ListItemText>
																{child_expanded ? <ExpandLess /> : <ExpandMore />}
															</ListItem>
															{children.map((child, index) => {
																if (UserPermissions[permission+'_child_child'] && (UserPermissions[permission+'_child'].includes(menu.id+'All') || UserPermissions[permission+'_child_child'].includes(child.id) || UserPermissions[permission+'_child_child'].includes('all'))) {
																	return (
																		<Collapse
																			key={index}
																			in={child_expanded}
																			timeout="auto"
																			unmountOnExit
																		>
																			<Link to={child.link ? child.link : "#"}>
																				<ListItem
																					button
																					className={clsx(
																						classes.divider,
																						classes.item,
																						classes.child,
																						child.active && classes.itemActiveItem
																					)}
																				>
																					<ListItemText
																						className={classes.child}
																						classes={{
																							primary: clsx(classes.itemPrimary, active && classes.itemActiveItem),
																						}}
																					>
																						{child.id}
																					</ListItemText>
																				</ListItem>
																			</Link>
																		</Collapse>
																	);
																}
															})}
														</>
													) : (
															<Link to={link ? link : "#"}>
																<ListItem
																	key={childId}
																	button
																	className={clsx(
																		classes.divider,
																		classes.item,
																		active && classes.itemActiveItem
																	)}
																>
																	<ListItemText
																		className={classes.child}
																		classes={{
																			primary: clsx(classes.itemPrimary, active && classes.itemActiveItem),
																		}}
																	>
																		{childId}
																	</ListItemText>
																</ListItem>
															</Link>
														)}
												</Collapse>
											);
										}
									}
								)}
								<Divider />
							</React.Fragment>
						)
					} else return null
				})}
			</List>
		</Drawer>
	);
}

Navigator.propTypes = {
	classes: PropTypes.object.isRequired,
	checkAccess: PropTypes.func.isRequired,
};

export default connect(null, { checkAccess })(withStyles(styles)(Navigator));
